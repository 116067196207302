<template>
  <div>
    <div
      style="
        width: 120vw;
        height: 100vh;
        position: fixed;
        z-index: 99999999999;
        pointer-events: none;
        opacity: 0.02;
        font-weight: 900;
      "
    >
      <span style="margin: 20px; font-size: 40px" v-for="item in 500"
        >青骊投资-{{ userStore ? userStore.name : '未登录' }}</span
      >
    </div>
    <router-view :key="$route.path" />
  </div>
</template>
<script>
import $ from 'jquery';
var _this;

export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {
    _this = this;
  },
};
</script>
<style></style>
